export default {
  name: "Logout",
  async beforeCreate() {
    await this.$router.replace({
      name: "login"
    });
  },
  render: createElement => {
    return createElement("div");
  }
};
